@import "~@/sass/main.scss";
















































.linked-in-btn {
  position: relative;
  width: 100%;
  height: 51px;
  border-radius: 8px;
  outline: none;
  border: 1px solid var(--v-lightGrey-base);
  background-color: var(--v-lightBeige-base);
  padding: 0 12px;
  cursor: pointer;
  .media-lg-desktop & {
    height: 66px;
  }
  &:hover {
    background-color: var(--v-beige-base);
  }
}
.linked-in-btn-text {
  font-size: 17px;
  .media-lg-desktop & {
    font-size: 26px;
    color: var(--v-darkPurple-base);
  }
}
.linked-in-logo {
  position: absolute;
  left: 14px;
}
.login-warning {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--v-black-base);
  margin-bottom: 15px;
  margin-top: 0;
  .media-lg-desktop & {
    font-size: 26px;
    margin-bottom: 25px;
    letter-spacing: 0.3px;
  }
}
.login-rules {
  opacity: 0.8;
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: center;
  color: var(--v-black-base);
  padding: 0px 20px;
  margin-top: 10px;
  margin-bottom: 0;
  .media-lg-desktop & {
    font-size: 21px;
    margin-top: 20px;
  }
}
.login-rules-link {
  font-weight: bold;
  color: var(--v-black-base);
}
