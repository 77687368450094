@import "~@/sass/main.scss";





































































































































































.v-input ::v-deep .mdi-close {
  font-size: 1.3rem;
}

.v-input ::v-deep textarea {
  font-size: 1rem;
  //color: grey !important;
}
.notice {
  color: grey;
}
