@import "~@/sass/main.scss";




































































































































































































































































































































































































































































































.v-dialog:not(.v-dialog--fullscreen) {
  margin: 10px;
}
