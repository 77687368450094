@import "~@/sass/main.scss";































































































.share-opp-rewards-dialog {
  .info-icon {
    background-image: none;
    background-color: #fff;
    color: #8E59FF;
  }
}
