@import "~@/sass/main.scss";



























































































































.title {
  line-height: 1.1em !important;
}
.role,
.location,
.date {
  color: grey;
}
