<template>
  <div class="spinner">
    <!-- <img src="../assets/loading.svg" alt="Loading"> -->
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Callback extends Vue {
  created () {
    // this.$store.dispatch('login')
    this.$router.push('/home')
  }
}
</script>

<style scoped>
.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
