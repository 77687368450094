@import "~@/sass/main.scss";









































.v-input ::v-deep .v-label {
  font-size: 1rem;
}
