@import "~@/sass/main.scss";






















































































































.user-achievements-list {
  display: inline-block;
  vertical-align: middle;
}
.achievement-tootlip {
  opacity: 1 !important;
  z-index: 200 !important;
  padding: 10px 40px 10px 10px;
  text-align: center;
  &.v-tooltip__content {
    pointer-events: all;
  }
}
.close-tooltip-btn {
  position: absolute;
  right: 0;
  top: 0;
}
