@import "~@/sass/main.scss";
















































.questions-list {
  list-style-type: decimal;
}
