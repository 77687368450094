<template>
  <v-dialog
    v-model="showDialog"
    :retain-focus="false"
    transition="slide-y-transition"
    :persistent="persistent"
    max-width="787"
    @click:outside="persistent ? undefined : hideModal"
  >
    <v-card
      :class="['pa-2 pt-0 pb-0', contentClass]"
      :style="contentStyle"
    >
      <v-container>
        <v-row
          no-gutters
          align="top"
        >
          <v-col class="headline">
            <InfoIcon
              class="info-dialog__headline-icon"
              cursor="default"
            />
            <span class="ml-2">{{ title }}</span>
          </v-col>

          <v-col cols="auto">
            <v-btn
              v-if="!persistent"
              small
              fab
              icon
              @click="hideModal"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          class="my-4 px-7"
          no-gutters
        >
          <v-col cols="12">
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import InfoIcon from '@/components/core/InfoIcon.vue'

@Component({
  components: {
    InfoIcon
  }
})
export default class InfoDialog extends Vue {
  @Prop({ required: true }) title: string
  @Prop({ required: true }) show: boolean
  @Prop({ default: false }) persistent: boolean
  @Prop() contentStyle: string
  @Prop() contentClass: string

  get showDialog (): boolean {
    return this.show
  }

  set showDialog (value: boolean) {
    this.$emit('update:show', value)
  }

  hideModal () {
    this.showDialog = false
    this.$emit('hide')
  }
}
</script>
<style scoped>
.info-dialog__headline-icon {
  position: relative;
  /* bottom: 8px !important; */
}
</style>
