@import "~@/sass/main.scss";













































































































































































// Theme styles overrides
.v-application .rounded {
  border-radius: 8px;
}

.theme--light .v-application a {
  color: inherit;
}

.json.user {
  background-color: black;
}

.grey-opaque {
  color: var(--v-grey-base);
}
.purple {
  color: var(--v-secondary-base);
  &.bg {
    background-color: var(--v-secondary-base);
  }
}

.theme--light.v-navigation-drawer {
  background-color: var(--v-beige-base);

  .v-navigation-drawer__border {
    display: none;
  }
}
.theme--light.v-navigation-drawer .v-divider {
  border-color: var(--v-lightGrey-base);
}

.v-item-group.v-bottom-navigation {
  box-shadow: none;
  border-top: 1px solid var(--v-lightGrey-base);
}

//text field overrides
.theme--light.v-text-field {
  .v-input__control {
    .v-input__slot {
      &::before {
        border-color: rgba(0,0,0,.3);
        border-style: dotted;
      }
    }
  }

  &.v-input--is-disabled {
    .v-input__control {
      .v-input__slot {
        &::before {
          border: none;
        }
      }
    }
  }
}
.headline {
  font-size: 2em !important;
  line-height: 1.2 !important;

  .media-md & {
    font-size: 2.5em !important;
  }

  .media-desktop & {
    font-size: 2.7em !important;
  }

  .media-lg-desktop & {
    font-size: 3em !important;
  }

  .info-icon {
    position: relative;
    bottom: 5px;
  }
}

//sheet and card overrides
.theme--light {
  .v-sheet,
  .v-data-table {
    background-color: var(--v-lightBeige-base);
  }
  .v-card {
    background-color: var(--v-beige-base);
  }
  .v-sheet, .v-card {
    box-shadow: none !important;
    border: none;

    &--outlined {
      border: 1px solid var(--v-grey-base);
      border-radius: 12px;
    }
  }

  .v-card {
    border-radius: 12px;
  }

  .v-dialog .v-sheet.v-card {
    background-color: var(--v-lightBeige-base);
  }

  header.v-app-bar.v-toolbar.v-sheet {
    background-color: rgba(247,236,223,0.8);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid var(--v-lightGrey-base);
  }
}
.theme--light.v-divider {
  border-color: var(--v-lightGrey-base);
}

//button overrides
.v-btn {
  text-transform: none;
  letter-spacing: normal;
  box-shadow: none !important;
}
.v-btn--rounded {
  border-radius: 8px;
}
.v-btn.v-size--small {
  font-size: 0.9rem;

  &:not(.v-btn--round) {
    padding: 4px 12px;
    height: 32px !important;
  }
}

//input overrides
.v-input__append-inner .v-icon {
  font-size: 18px;
}
.theme--light.v-text-field--solo {
  outline: none;
  .v-input__control > .v-input__slot {
    background-color: var(--v-lightBeige-base) !important;
    box-shadow: none !important;
    border: 1px solid var(--v-lightGrey-base) !important;
    border-radius: 8px;
    outline: none;

    fieldset {
      border: none;
      outline: none
    }
  }

  &.v-input--is-focused {
    .v-input__control > .v-input__slot {
      border-color: var(--v-grey-base) !important;
      background-color: var(--v-beige-base) !important;
    }
  }

  &.v-select--is-menu-active {
    .v-input__control > .v-input__slot {
      background-color: var(--v-beige-base) !important;
    }
  }
}

.v-input__control > .v-input__slot:not(:empty) {
  min-height: 48px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel,
.select-with-groups__content.v-card {
  background-color: var(--v-lightBeige-base);
  border: 1px solid var(--v-lightGrey-base);
  border-radius: 8px;

  .v-icon {
    font-size: 18px;
  }
}

.v-expansion-panel {
  &:not(.v-expansion-panel--active) {
    .v-divider{
      display: none;
    }
  }
  &.v-expansion-panel--active {
    .v-expansion-panel-header{
      min-height: 48px;
    }
  }
}

.v-expansion-panel::before {
  box-shadow: none;
}
.v-divider

.v-autocomplete__content.v-menu__content,
.v-menu__content,
.v-menu {
  background-color: var(--v-beige-base);
  box-shadow: inset 0 0 5px 0 rgba(0,0,0,0.2);
  border-radius: 8px;
  
  .v-select-list.v-sheet,
  .v-sheet,
  > .v-card {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }
}


.custom-input {
  &.v-input {
    font-size: 1em;
    .v-label {
      font-size: 1.4em;
      height: 30px;
    }
    input {
      padding: 20px 0 5px;
      font-size: 0.9em;
    }
    textarea {
      padding: 15px 0 5px;
      font-size: 0.9em;
      line-height: normal;
    }
  }
  .ql-editor {
    &.ql-blank::before {
      font-style: normal;
    }
  }
}

// tab nav
.theme--light.v-tabs > .v-tabs-bar {
  background-color: var(--v-lightBeige-base);
}

.theme--light.v-tabs-items {
  background-color: var(--v-lightBeige-base);
}

//table
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--v-beige-base);
}

body {
  background-color: var(--v-lightBeige-base);
}

.v-app {
  .link {
    text-decoration: underline;
    color: currentColor;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .vertical-align-middle {
    vertical-align: middle;
  }

  .no-ellipsis {
    white-space: normal;
    text-overflow: initial;
  }

  .reset-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .v-application--wrap {
    min-height: 660px;
  }

  .v-card__title {
    word-break: normal;
  }

  .v-btn:not(.v-btn--round).v-size--x-small {
    .v-progress-circular {
      // had to set !important modifier
      height: 16px !important;
      width: 16px !important;
    }
  }
  .slide-x-reverse-transition,
  .slide-y-transition {
    will-change: transform;
  }

  .v-carousel__controls__item.v-btn.v-item--active.v-btn--active {
    color: var(--v-primary-base) !important;
    .v-icon {
      opacity: 1
    }
  }
}

