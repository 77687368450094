@import "~@/sass/main.scss";

































































































































.v-select ::v-deep .v-select__selections {
  margin: 0;
}
.v-input ::v-deep .v-input__slot {
  padding: 5px 10px 0px;
}
.autocomplete-select .v-input ::v-deep .v-label {
  font-size: 1.25rem;
}
