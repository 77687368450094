@import "~@/sass/main.scss";



















































































































































































































































.title {
  line-height: 1.1em !important;
  white-space: normal;
}
.role, .location, .date, .network {
  color: grey;
  letter-spacing: 1px;
}
.counters {
  margin: 13px 0 0 5px;
}
.counter-badge {
  width: auto;
  margin-left: 5px;
}
.shared-to-me {
  background: var(--v-darkPurple-lighten5);
}
