@import "~@/sass/main.scss";


















































































































































































































































































































.control-drag-icon,
.answer-options ::v-deep .mdi-drag {
  cursor: move;
}
.question-control ::v-deep .v-text-field__details {
  margin-bottom: 0;
}
