@import "~@/sass/main.scss";



































































































































































































































































@import '~tinymce/skins/ui/oxide/skin.min.css';
// @import '~tinymce/skins/ui/oxide/content.min.css';
// @import '~tinymce/skins/content/default/content.min.css';

.vue-html-editor ::v-deep {
  iframe {
    display: block;
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
    height: 30vh;
  }
}
