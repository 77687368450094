@import "~@/sass/main.scss";































































































































.logo {
  .media-desktop & {
    margin-top: 18px;
    padding-bottom: 60px !important;
  }
}
.copy {
  color: grey;
}
.slider {
  max-width: 500px;
  margin: 0 auto;
  z-index: 1;
  padding-bottom: 10px;
  
  .media-desktop & {
    max-width: 700px;
    padding-bottom: 15px;
  }

  .media-lg-desktop & {
    max-width: 900px;
    height: auto;
  }
}
.carousel-item__header {
  .media-lg-desktop & {
    margin-top: 32px !important;
    font-size: 36px !important;
  }
}
.carousel-item__body {
  .media-lg-desktop & {
    font-size: 21px !important;
  }
}

.platform-label {
  position: relative;
  max-width: 520px;
  padding: 0 20px;
  margin: 10px auto 0 auto;
  text-align: center;
  font-size: 14px;
  z-index: 1;
  .media-lg-desktop & {
    margin-top: 19px;
    font-size: 20px;
    max-width: 620px;
  }
}
.shape-bg {
  width: 100vw;
}
.bottom-nav {
  position: absolute;
  left: 0;
  bottom: 10px;
  margin: 0 10px;
  border-radius: 12px;
  background-color: var(--v-beige-base);
  width: calc(100% - 20px);
  padding: 10px;

  .media-desktop & {
    padding: 40px;
  }
}
.linked-in-wrapper {
  margin: 0 auto;
  max-width: 306px;
  height: 145px;
  z-index: 1;

  .media-lg-desktop & {
    max-width: 490px;
    height: auto;
  }
}
