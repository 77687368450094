@import "~@/sass/main.scss";



























































































































































































































































































































.role,
.location {
  color: grey;
}
.location {
  font-size: 0.8em;
}
.no-ellipsis {
  white-space: normal;
  text-overflow: initial;
}
