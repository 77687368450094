@import "~@/sass/main.scss";

















































































































































.card-title {
  font-size: 1.3em;
  line-height: 1.3em;
}
.more-info-container {
  width: 100%;
}
