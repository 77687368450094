@import "~@/sass/main.scss";













































































































.dialog-content {
  position: relative;
  z-index: 1;
  max-width: 306px;
  .media-lg-desktop & {
    max-width: 490px;
  }
}
.login-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.login-btn-wrap {
  position: relative;
  margin: 0 auto;
  width: 100%;
  .media-lg-desktop & {
    height: auto;
  }
}
.dialog-bg-image {
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: 250px;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url('~@/assets/images/login/login-waves-bg-desktop.svg');
  background-size: cover;
  background-position: top center;
  .media-xs & {
    background-image: url('~@/assets/images/login/login-waves-bg-mobile.svg');
    max-height: 240px;
  }
  .media-lg-desktop & {
    max-height: 430px;
  }
}
.apply-title {
  font-size: 16px;
  line-height: 1.25;
  padding: 15px 5px 20px 5px;
  .media-lg-desktop & {
    font-size: 26px;
    padding-bottom: 25px;
  }
}
