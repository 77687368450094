@import "~@/sass/main.scss";



















































































































































































































































































































































































































































































































































































































































































































.main-panel {
  padding-bottom: 60px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  border: none !important;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  z-index: 1;
}
