@import "~@/sass/main.scss";

















.page-wrap {
  background: var(--v-lightGrey-base);
}
.page-content {
  max-width: 700px;
  margin: 0 auto;
}
