<template>
  <div
    class="notifications-container"
    ref="notificationsContainer"
    :style="containerStyle"
  >
    <Pagination
      v-if="isMounted"
      :loader-size="25"
      :is-fetching="pagination.isFetchingMore"
      :total="notificationsTotal"
      :observer-options="{root: $containerRef, rootMargin: '0px 0px 128px 0px'}"
      @fetchMore="handleFetchMore"
    >
      <v-list-item
        v-for="item in displayNotifications"
        :key="item.title"
        class="mb- pt-0 pb-0 pl-2 pr-2"
        three-line
      >
        <v-list-item-icon class="mx-auto my-auto mr-2">
          <v-icon
            v-if="messages[item.type].icon"
            v-text="messages[item.type].icon"
          />
          <v-img
            v-if="messages[item.type].image"
            :src="require('@/' + messages[item.type].image)"
            max-height="22"
            contain
          />
        </v-list-item-icon>

        <v-list-item-subtitle
          class="grey-opaque"
        >
          <template v-if="item.type === 'opportunity'">
            <span v-if="item.message === 9">
              {{ item.content[0] }} shared&nbsp;
              <router-link
                class="purple--text"
                :to="`/opportunities/${item.content[2]}`"
              >
                {{ item.content[1] }}
              </router-link> with you
            </span>

            <span v-else-if="item.message === 10">
              {{ item.content[0] }} shared your&nbsp;
              <router-link
                class="purple--text"
                :to="`/opportunities/${item.content[2]}`"
              >
                {{ item.content[1] }}
              </router-link>
            </span>

            <span v-else>
              {{ messages[item.type].message[item.message](...item.content) }}
            </span>
          </template>

          <template v-else>
            <span>
              {{ messages[item.type].message[item.message](...item.content) }}
            </span>
          </template>
        </v-list-item-subtitle>
      </v-list-item>
    </Pagination>
  </div>
</template>

<script lang="ts">
import messages from '@/assets/notifications'
import networkTypes from '@/assets/network-types'
import Pagination from '@/components/core/Pagination.vue'

import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { IPagination } from '@/types/api'
import INotification from '@/types/models/notification.model'

@Component({
  components: {
    Pagination
  },
  computed: mapGetters([
    'notifications',
    'notificationsTotal'
  ])
})
export default class NotificationMessages extends Vue {
  @Prop() seeMore: boolean

  $refs: {
    notificationsContainer: HTMLDivElement
  }

  // data
  isMounted: boolean = false
  networkTypes: any = networkTypes
  messages: any = messages
  noItems: any = [{
    icon: messages.default.icon,
    content: messages.default.message
  }]
  itemHeight: number = 64
  pagination: any = {
    isFetchingMore: false
  }

  // state
  notifications: INotification[]
  notificationsTotal: number

  get displayNotifications (): any {
    if (!this.notifications.length) return [{ type: 'default', message: 1 }]
    return this.notifications
  }

  get containerHeight (): number {
    return this.itemHeight * (this.seeMore ? 6 : 3)
  }

  get containerStyle (): string {
    return `max-height: ${this.containerHeight}px`
  }

  get $containerRef (): HTMLDivElement {
    return this.$refs.notificationsContainer
  }

  async handleFetchMore ({ page, limit }: IPagination) {
    this.pagination.isFetchingMore = true
    await this.$store.dispatch('fetchMoreNotifications', { page })
    this.pagination.isFetchingMore = false
  }

  created () {
    this.$store.dispatch('listNotifications')
  }

  mounted () {
    // needs for having root contaner $ref for scrolling pagination
    this.isMounted = true
  }

}
</script>
<style scoped>
.notifications-container {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
