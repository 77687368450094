@import "~@/sass/main.scss";

























































































































































































































































































































































































































































































































.v-tabs-bar.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows):not(.v-slide-group--has-affixes) .v-slide-group__prev{display: none !important}
