@import "~@/sass/main.scss";
































.info-icon {
  font-style: normal;
  width: 25px;
  height: 25px;
  display: inline-block;
  font-size: 19px;
  text-align: center;
  line-height: 22px;
  border-radius: 50%;
  background-image: linear-gradient(90deg, var(--v-purple-base), var(--v-purple-lighten2));
  color: #fff;
  font-weight: 300;
}
