@import "~@/sass/main.scss";































.v-input {
  font-size: 1em;
}
.v-input ::v-deep .v-label {
  font-size: 1.75em;
  height: 30px;
}
.v-input ::v-deep input {
  padding: 20px 0 5px;
  font-size: 0.9em;
}
.v-input ::v-deep textarea {
  padding: 15px 0 5px;
  font-size: 0.9em;
  line-height: normal;
  //color: purple !important;
}
