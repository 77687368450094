@import "~@/sass/main.scss";

















































































































































.underline {
  text-decoration: underline;
}
.v-input ::v-deep .v-label {
  font-size: 1rem;
}

.v-input ::v-deep .mdi-close {
  font-size: 1.3rem;
}
.v-input--is-disabled {
  color: var(--v-grey-base);
}
