@import "~@/sass/main.scss";












































































































.main-panel {
  background: var(--v-orange-base);
  .body {
    color: white;
  }
}
.v-input--selection-controls {
  margin-top: 0;
}
