@import "~@/sass/main.scss";








































































































































































































.note {
  color: grey;
}
.v-input {
  font-size: 1rem;
}
