@import "~@/sass/main.scss";









































































































.share-opp-rewards-dialog {
  &.v-card,
  .v-btn.v-btn--icon,
  a {
    color: #fff !important;
  }

  .info-icon {
    background-image: none;
    background-color: #fff;
    color: #8E59FF;
  }
}
