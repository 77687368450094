@import "~@/sass/main.scss";


























































































































































































































































































































































































































































































































.location {
  color: grey;
}
.image {
  border-radius: 50%;
}
.view-table {
  td {
    padding: 5px 0;
    border-bottom: none !important;
  }
  tr {
    td:first-of-type {
      min-width: 100px;
      padding-right: 20px;
      padding-left: 0;
      vertical-align: top;
    }
    td:last-of-type {
      text-align: right;
      padding-right: 0px;
    }
  }
}
.not-filled {
  color: grey;
}
