@import "~@/sass/main.scss";

























































































































































































































.view-table {
  td {
    padding: 7px 0;
    border-bottom: none !important;
  }
  tr {
    td:first-of-type {
      // color: grey;
      min-width: 100px;
      padding-right: 20px;
      vertical-align: top;
    }
    td:last-of-type {
      text-align: right;
    }
  }
}
.main-panel {
  padding-bottom: 60px !important;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
