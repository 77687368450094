@import "~@/sass/main.scss";









































.image {
  border-radius: 50%;
}
