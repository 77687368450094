@import "~@/sass/main.scss";

























































































































.referrals-list-scroller {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 380px;
}
