@import "~@/sass/main.scss";

























































































































































































































.section-card {
  background-color: var(--v-purple-base) !important;
  color: white;
  &:hover {
    .v-image ::v-deep .v-image__image {
      opacity: 1;
    }
  }
}
.section-title {
  font-size: 1.5em !important;
  font-family: 'Satoshi-Bold';
  color: white;

  .media-xs & {
    font-size: 1em !important;
    line-height: 1em;
    padding: 8px;
  }
}
.v-image ::v-deep .v-image__image {
  background-position: right top !important;
  opacity: 0.8;
}
.welcome-dialog-slider ::v-deep {
  .v-carousel__controls {
    height: 20px;
    .v-btn--icon {
      width: 18px;
      height: 18px;
    }
    .v-btn__content .v-icon {
      font-size: 8px !important;
    }
  }
}
