@import "~@/sass/main.scss";



















































.v-label.v-label--active {
  transform: scale(0.75);
}
.value {
  line-height: 17px;
  white-space: pre-line;
}
