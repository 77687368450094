@import "~@/sass/main.scss";




































































































.v-btn--disabled {
  opacity: 0.2 !important;
}
