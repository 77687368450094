@import "~@/sass/main.scss";





















































































































































.select-with-groups {
  font-size: 1.25rem;
}
.no-ellipsis {
  white-space: normal;
  text-overflow: initial;
}
.select-with-groups__checkbox {
  width: 100%;
}
::v-deep .v-list-item--active:before,
.v-list-item--active::before {
  opacity: 0.12 !important;
}
